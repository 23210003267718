import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/layout';
import { FaCircle, FaRegCircle } from 'react-icons/fa';

import { Reviews } from '../Reviews';

const ONas = () => {
    const [reviewsNav, setReviewsNav] = useState<number>(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (reviewsNav !== 4) setReviewsNav(reviewsNav + 1);
            if (reviewsNav === 4) setReviewsNav(0);
        }, 8000);

        return () => clearTimeout(timeout);
    }, [reviewsNav]);

    return (
        <Layout
            title="O nas - WrapTown - profesjonalne oklejanie samochodów"
            desc="Dowiedz się nowych informacji o naszej firmie WrapTown - profesjonalne oklejanie samochodów. Siedzibę mamy w Kościanie, niedaleko Poznania."
            url="https://wraptown.pl/o-nas"
            type="website"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> / O nas
                    </p>
                </div>
                <div>
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        O nas - WrapTown
                    </h1>
                    <p className="mb-6">
                        Od 2019 roku zajmujemy się oklejaniem samochodów. Nasz
                        garaż znajduję się w Kościanie, niedaleko Poznania. W
                        firmie WrapTown możesz wybrać usługi takie jak: zmiana
                        koloru auta, dechroming czy ochrona lakieru folią PPF.
                    </p>
                </div>
                <div className="mt-12 flex flex-col items-center">
                    <h2 className="text-center text-2xl mb-6 font-bold text-black">
                        Opinie klientów
                    </h2>
                    <div className="bg-gray-100 w-full md:w-3/5 rounded-t-lg shadow-xl px-10 py-4">
                        {Reviews.map((review) => {
                            if (review.id === reviewsNav) {
                                return (
                                    <div key={review.id}>
                                        <h3 className="font-semibold mb-2">
                                            {review.name}
                                        </h3>
                                        <p className="mb-6">{review.desc}</p>
                                        <div>
                                            <span>⭐</span>
                                            <span>⭐</span>
                                            <span>⭐</span>
                                            <span>⭐</span>
                                            <span>⭐</span>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className="mt-8">
                        <ul className="flex space-x-4 fill-current text-gray-400">
                            <li>
                                <button
                                    onClick={() => {
                                        setReviewsNav(0);
                                    }}
                                >
                                    {reviewsNav === 0 ? (
                                        <FaCircle size={10} />
                                    ) : (
                                        <FaRegCircle size={10} />
                                    )}
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        setReviewsNav(1);
                                    }}
                                >
                                    {reviewsNav === 1 ? (
                                        <FaCircle size={10} />
                                    ) : (
                                        <FaRegCircle size={10} />
                                    )}
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        setReviewsNav(2);
                                    }}
                                >
                                    {reviewsNav === 2 ? (
                                        <FaCircle size={10} />
                                    ) : (
                                        <FaRegCircle size={10} />
                                    )}
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        setReviewsNav(3);
                                    }}
                                >
                                    {reviewsNav === 3 ? (
                                        <FaCircle size={10} />
                                    ) : (
                                        <FaRegCircle size={10} />
                                    )}
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        setReviewsNav(4);
                                    }}
                                >
                                    {reviewsNav === 4 ? (
                                        <FaCircle size={10} />
                                    ) : (
                                        <FaRegCircle size={10} />
                                    )}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default ONas;
