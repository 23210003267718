interface Review {
    id: number;
    name: string;
    desc: string;
    stars: number;
}

export const Reviews: Review[] = [
    {
        id: 0,
        name: 'Artur',
        desc: 'Tak jak mieliśmy umówione tak też wszystko zostało wykonane. Rzetelna firma, bardzo dobry kontakt, konkurencyjne ceny i świetna jakość wykonania 👍 Choć do Kościana mam 40 minut drogi, to bez zastanowienia wybrałbym Wraptown ponownie 👍',
        stars: 5,
    },
    {
        id: 1,
        name: 'Agnieszka',
        desc: 'Pełen profezjonalizm, doradzono mi w kwestii odpowiedniego koloru (nie wiedzialam, że tyyyyle jest odcieni białego😅); polecam👍.',
        stars: 5,
    },
    {
        id: 2,
        name: 'Bartek',
        desc: 'Polecam za jakość i pełen profesjonalizm . Robiłem folię ochronną i jestem bardzo zadowolony z wykonanej przez Wrap Town pracy .',
        stars: 5,
    },
    {
        id: 3,
        name: 'Jakub',
        desc: 'Nigdy nie miałem lepszej folii',
        stars: 5,
    },
    {
        id: 4,
        name: 'Wiktor',
        desc: 'Pełen profesjonalizm. Fachowe doradztwo. Precyzja wykonania. Przystępna cena. Wykonawca godny polecenia.',
        stars: 5,
    },
];
